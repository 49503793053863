/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/


$(function() {


    $('select').selectpicker();


    //MOBILE MENU
    $(document).on('click', '.menu-toggle', function (e) {
        e.preventDefault();
        $(".menu-mobile").addClass("active");
    });

    $(document).on('click', '.menu-mobile-close', function (e) {
        e.preventDefault();
        $(".menu-mobile").removeClass("active");
    });

    $('.menu-mobile').on('swiperight', function (e) {
        $(".menu-mobile").removeClass("active");
    });

    //MODAL FIRE
    $(document).on('click', '.btn-fireoccured', function (e) {
        e.preventDefault();
        $('#modal-fire').modal('show');
    });

    $('#modal-fire').on('hidden.bs.modal', function (e) {
        console.log("close modal");
        $(".btn-fireoccured").find("input[value='Yes']").parent().removeClass("active");
        $(".btn-fireoccured").find("input[value='No']").parent().addClass("active");
        $(".btn-fireoccured").find("input[value='No']").removeAttr("checked");
    });


    //MODAL REPAIR
    $(document).on('click', '.btn-repairneeded', function (e) {
        e.preventDefault();
        $('#modal-repair').modal('show');
    });

    $('#modal-repair').on('hidden.bs.modal', function (e) {
        console.log("close modal");
        $(".btn-repairneeded").find("input[value='Yes']").parent().removeClass("active");
        $(".btn-repairneeded").find("input[value='No']").parent().addClass("active");
    });

    //MAILCHIMP
    $('.mailchimp').submit(function (e) {
        // Prevent the form from actually submitting
        e.preventDefault();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: $(this).serialize(),
            cache: false,
            beforeSend: function () {
                $('.mailchimp .btn-submit').html("<span class='preloader'></span><span style='padding-left:16px;'>sending</span>");
            },
            error: function () {
                console.log("error");

            }
        }).done(function (data) {
            //success...
            console.log(data);
            if (data.success) {
                // remove loading and show success message
                var tempcontent = "Thanks! You will receive our newsletter.";
                $('.response').addClass('alert-secondary').removeClass('alert-danger');
                $(".response").show();
                $(".response").html(tempcontent);
                $('.mailchimp').hide();
            }
            // errors...
            else {
                if (data.errors) {
                    var tempcontent = "Please type in your email address."
                    $('.response').addClass('alert-danger').removeClass('alert-secondary');
                    $(".response").show();
                    $(".response").html(tempcontent);
                    // $.each(data.errors, function(field, msgs){
                    //     var tempcontent = msgs.toString();
                    //     $(".response").append(tempcontent + "<br>");
                    // });

                    $('.mailchimp .btn-submit').html("Submit");
                }
            }
        });
    });


    //MODAL FOR TEAM DETAIL
    $(document).on('click', '.team .team-1 a', function (e) {
        e.preventDefault();
        var id = $(this).attr('id');
        var personname = $(this).find('#person-name').html();
        var position = $(this).find('#position').html();
        var body = $(this).find('#body').html();
        var profileimage = $(this).find('#profileimage').attr("src");


        $('#modal-team').find('#person-name').html(personname);
        $('#modal-team').find('#position').html(position);
        $('#modal-team').find('#body').html(body);
        $('#modal-team').find('#profileimage').attr('src', profileimage);


        $('#modal-team').modal('show');
        console.log(profileimage);
    });
});

//MODAL FOR ADDING APPLICANTS
var appId = 1;
$(document).ready(
    function () {
        $("#btn-plus").click(
            function () {
                addApplicant();
            }
        );
    }
)
//UPDATE DATE INTO TEXTAREA
$("#otherApplication").change(
    function () {
        var content = "\t No. \t Name \t\t D.O.B. \t\t Income type";
        $(".other-applicant").each(function () {
            // console.log($(this).attr('id'));
            content = content + "\n" + insertDate($(this).attr('id'));
        });
        // console.log("Total:" + $(".other-applicant").length);
        $('#ApplicationsData').val(content);
    }
);
//REMOVE APPLICANT
$(".app-remove").each(function () {
    $(this).click(function () {
        console.log($(this).attr('id'));
        var id = $(this).attr('id').split('-');
        $("other-applicant-" + id[2]).remove();
    });
});


$("#housing-submit").click(function () {
    $(".housing-app").submit(function (e) {
        e.preventDefault();
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: $(this).serialize(),
            cache: false,
            beforeSend: function () {
                $("#housing-submit").addClass("disabled");
                $("#housing-submit").html("<span>Submitting</span>");
            },
            error: function () {
                console.log("error");
            }
        }).done(function (data) {
            //success...
            console.log(data);
            if (data.success) {
                // remove loading and show success message
                $("#housing-submit").html("<span>Done</span>");
                alert('Thanks for your application. We will contact you soon.');
                window.location.reload();
            }
            // errors...
            else {
                $("#housing-submit").removeClass("disabled");
                if (data.errors) {
                    var tempcontent = "Something go wrong, please try again."
                    $('.response').addClass('alert-danger').removeClass('alert-secondary');
                    $(".response").show();
                    $(".response").html(tempcontent);
                    // $.each(data.errors, function(field, msgs){
                    //     var tempcontent = msgs.toString();
                    //     $(".response").append(tempcontent + "<br>");
                    // });
                    $('#housing-submit').html("Re-Submit");

                }
            }
        });
    });
});

function addApplicant() {
    // console.log(appId);
    var objectId;
    var currentId = appId;
    if (appId == 1) {
        objectId = '#other-heading';
    } else {
        var lastIndex = appId - 1;
        objectId = '#other-applicant-' + lastIndex;
        while ($(objectId).length == 0 && lastIndex > 0) {
            console.log("not exist." + lastIndex);
            lastIndex = lastIndex - 1;
            objectId = '#other-applicant-' + lastIndex;
        }
        if (lastIndex == 0) {
            objectId = '#other-heading';
        }
    }
    // console.log(objectId);
    $(objectId).after(
        "    <div class=\"form-row other-applicant\" id=\"other-applicant-" + appId + "\">\n" +
        "    <div class=\"form-group col-md-1\">\n" +
        "    <label>#</label>\n" +
        "    <input type=\"text\" class=\"form-control form-control-lg\" id=\"app-seq-" + appId + "\" value=\"" + appId + "\" disabled>\n" +
        "    </div>\n" +
        "    <div class=\"form-group col-md-3\">\n" +
        "    <label>Name</label>\n" +
        "    <input class=\"form-control form-control-lg\" name=\"name\" id=\"app-name-" + appId + "\" type=\"text\" required>\n" +
        "    </div>\n" +
        "    <div class=\"form-group col-md-2\">\n" +
        "    <label>D.O.B</label>\n" +
        "    <input class=\"form-control form-control-lg\" type=\"date\" id=\"app-dob-" + appId + "\"name=\"dob\" value=\"\" required>\n" +
        "    </div>\n" +
        "<div class='form-group col-md-1' id='app-remove-" + appId + "'><a href='#' class='app-remove' ><i class='fa fa-close'></i></a></div>" +
        "    <hr>\n" +
        "    </div>"
    );
    console.log("added after:" + objectId);
    $("#app-remove-" + currentId).on('click',
        function () {
            console.log("#other-applicant-" + currentId);
            $("#other-applicant-" + currentId).remove();
            var content = "\t No. \t Name \t\t D.O.B.";
            $(".other-applicant").each(function () {
                content = content + "\n" + insertDate($(this).attr('id'));
            });
            $('#ApplicationsData').val(content);
        }
    );
    appId = appId + 1;
}

function insertDate(formId) {
    formId = "#" + formId;
    var singleValue = "";
    //Get all text field value
    $(formId + " :input:text").each(
        function () {
            singleValue = singleValue + "\t" + $(this).val();
        });
    //Get D.O.B.
    $(formId + ' :input[type="date"]').each(
        function () {
            singleValue = singleValue + "\t" + $(this).val();
        }
    );
    return singleValue;
}